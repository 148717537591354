// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


// html {
//     scroll-behavior: smooth;
//   }


// cookie consent styles

.js-cookie-consent.cookie-consent {
    margin: 1.2rem;
    position: fixed;
    left: 15rem;
    bottom: 0;
    z-index: 1000;
    background-color: red;
    padding: 20px;
    color: white;
    left: 50%;
    transform: translateX(-50%);

    button {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        padding: 0.35rem 1.5rem;
        background-color: #CF4141ed;
        border: none;
        color: white;
    }
}


body {
    background-color: #ffffff;
}
* {
    scrollbar-color: var(--scroll-bar-bg-color) transparent;
    scrollbar-width: thin;
}


.container {
    max-width: 1300px;
}

img{
    max-width: 100%;
    display: block; /* remove extra space below image */
}

.rowgfh {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
}

.pulsate {
    -webkit-animation: pulsate 3s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.5;
    color: red;
}
@-webkit-keyframes pulsate {
    0% { 
        opacity: 0.5;
    }
    50% { 
        opacity: 1.0;
    }
    100% { 
        opacity: 0.5;
    }
}


h2.rowgfh {
    margin: auto;
    text-align: center;
    font-family: 'Sacramento', cursive;
    padding-top: 2rem;
    font-size: 3rem;
}
.top-note {
    background-color: red;
    color: white;
    text-align: center;
    top: 0;
    z-index: 1000;
    font-size: 1.3rem;
    text-transform: uppercase;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    font-weight: 400;

    a {
        color: white;
        text-decoration: none;
    }
}
.nav-link.btn {
    border-radius: 2px;
}
.navbar-nav {
    align-items: center;
}
.navbar-light .navbar-nav .nav-link{
    font-family: Share Tech Mono;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
}

.navbar-light .navbar-nav > li > a:hover, .navbar-light .navbar-nav > li > a:focus {
    color: #FFC0CB;
}

.nav-link:hover { color: #ffb0b0 !important; }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: rgba(255, 255, 255, 0.9);
}



.navbar .nav-item:not(:last-child) {
    margin-right: 15px;
  }
  
  .dropdown-toggle::after {
     transition: transform 0.15s linear; 
  }
  
  .show.dropdown .dropdown-toggle::after {
    transform: translateY(3px);
  }
  
  .dropdown-menu {
    margin-top: 0;
  }
  

// .white 
 .navbar-light .navbar-toggler-icon {
    background-image: url(/img/menu.png);
}

.navbar-toggler-icon-dark {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iMzJweCIgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHdpZHRoPSIzMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNCwxMGgyNGMxLjEwNCwwLDItMC44OTYsMi0ycy0wLjg5Ni0yLTItMkg0QzIuODk2LDYsMiw2Ljg5NiwyLDhTMi44OTYsMTAsNCwxMHogTTI4LDE0SDRjLTEuMTA0LDAtMiwwLjg5Ni0yLDIgIHMwLjg5NiwyLDIsMmgyNGMxLjEwNCwwLDItMC44OTYsMi0yUzI5LjEwNCwxNCwyOCwxNHogTTI4LDIySDRjLTEuMTA0LDAtMiwwLjg5Ni0yLDJzMC44OTYsMiwyLDJoMjRjMS4xMDQsMCwyLTAuODk2LDItMiAgUzI5LjEwNCwyMiwyOCwyMnoiLz48L3N2Zz4=");
  }

.logo {
    width: 10rem;
    height: auto;
}

#home {
background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('https://res.cloudinary.com/du2ymf1s7/image/upload/v1629894093/GFHLIVE/homepage_dky1al.jpg');
height: 100vh;
background-position: center;
background-size: cover;
margin: 0 auto;
}

// header content 
.seriescnt {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.series {
    color: white;
    text-align: center;
    margin-top: 50px;

    h1 {
        //font-family: 'Cookie', cursive;
        font-size: 3.3rem;
    }
}
.vid {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vid img {
    width: 100%;
}


.vision {
    width: 533px;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
    margin-bottom: 2rem;

    p{
        font-size: 19px;
    }
}
.vision-btn {
    display: flex;
    justify-content: center;

}
.btn-outline-dark {
    border-radius: 15px;

}
.row {
    margin-right: 0;
    margin-left: 0;
    width: 700px;
    margin: auto;
    display: flex;
    justify-content: center;

    .col {
        width: 32%;
        margin-bottom: 2rem;
        display: flex;
        height: 325px;
    
        img {
            object-fit: cover;
            border-radius: 10px;
        }
        .col-cnt {
            color: white;
            position: absolute;
            left: 50%;
            text-align: center;
            top: 50%;
            font-family: 'Sacramento', cursive;
            transform: translate(-50%, -50%);
            font-size: 3rem;
        }
    }
}
.colss:after {
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; 
    height:100%;
    display:inline-block;
    background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }
  .colss img{
    display:block;
  }






.col-sm{
    // margin: 0 0.7% 2.5%;
    // border-radius: 7px;
    // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
    // background: white;
    // width: 400px;
    // height: 90px;
    width: 311px;
    margin: 0 0.7% 2.5%;
    background: #FFFFFF;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-right: 0;
    padding-left: 0;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    .col-cnt {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    
    }
}

//sermons, TW, trybe, crock

.a-col {
    display: flex;
}

.a-row {
    display: flex;
    width: 50%;
}


// live
.stream {
    margin-top: 2rem;
    margin-bottom: 10rem;
    img {
        width: 250px;
    }
}

// journey
.home-box {
    position: relative;
    width: 670px;
    margin: auto;
    margin-bottom: 2rem;
    .btn {
        background-color: #0000;
        border: 2px solid;
        border-radius: 10px;
    }
    p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 0.5rem;
    }

    h1 {
     font-family: 'Satisfy', cursive;
    }
}
.homeIMG::after {
    display: block;
    position: relative;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #fff 100%);
    margin-top: -150px;
    height: 150px;
    width: 100%;
    content: '';
  }


.home-box {
    clear: both;
}
.homestuff  {
    width: 100%;
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    .homeIMG {
        position: relative;
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
    }
    .homeIMG-cnt {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
        padding-left: 2rem;
        color: white;
    }
    h1 {
        font-family: 'Roboto', sans-serif;       }
}

.outreach {
    display: flex;
    margin: 0 auto;
    width: 820px;

}

.outreach-box{
margin: 0 auto;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin-bottom: 25px;
position: relative;

}
.outreach-cnt {
    text-align: center;
    justify-content: space-between;
    display: block;
    width: 320px;
    position: absolute;
    top: 0;
    color: white;
}

.outreach-img {
    border-radius: 20px;
    -webkit-box-shadow: -7px -3px 52px -19px rgba(0,0,0,0.54);
    -moz-box-shadow: -7px -3px 52px -19px rgba(0,0,0,0.54);
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
    max-width: 400px;

}



// ABOUT US

.vimi {
    display: flex;
    margin-top: 60px;
}
.vision2 {
    width: 50%;
    text-align: center;
}
.visioncnt {
    max-width: 400px;
    margin: 0 auto;
}

.vimi h1 {
    font-family: 'Share Tech Mono', monospace;
    color: #BE2828;
    text-transform: lowercase;
}
.mission {
    width: 50%;
    text-align: center;
}
.missioncnt {
    max-width: 400px;
    margin: 0 auto;
}

.somequote {
    text-align: center;
    margin-bottom: 2rem;
}


// ANimations
/* ----------------------------------------------
 * Generated by Animista on 2020-5-24 8:39:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */

//  .slide-top {
// 	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// 	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
// }
 
//  @-webkit-keyframes slide-top {
//     0% {
//       -webkit-transform: translateY(0);
//               transform: translateY(0);
//     }
//     100% {
//       -webkit-transform: translateY(-100px);
//               transform: translateY(-100px);
//     }
//   }
//   @keyframes slide-top {
//     0% {
//       -webkit-transform: translateY(0);
//               transform: translateY(0);
//     }
//     100% {
//       -webkit-transform: translateY(-100px);
//               transform: translateY(-100px);
//     }
//   }





.somequotebody {
    width: 650px;
    margin: 0 auto;
    color: grey;
    opacity: 0.8;
}


.sidebody {
    display: flex;
}

// SIDEBAR CSS
.navigation {
    margin-left:1px;
    height:100%;
     width:130px;
    font-style: normal;
    //  font-family:monospace;
    //  font-size:1em;
    //  color: red;
}

.navlist a:hover {
color:#A31515;}
.navlist a:active {
    color: #A31515;
}

.navlist{
border-color:black;
border-radius:.3em .3em .3em .3em;
width:200px;
display:block;
margin-bottom:3px;
padding-left:0px;
padding-right:14px; }

.navlist ul li{float:left;
}
.navlist li {
    list-style-type:none;
}



.navlist li a {
    color:black;
    display: block;
    border-bottom: solid black;
    text-align:center;
    text-decoration:none;
    width:120px;
    float:left;
    padding:12px;
    transition: color 0.4s ease;
    border-bottom-width: thin;
}

// END OF SIDEBAR CSS

// ABOUT US CSS


// nextsteps 
// .givenav2 {
//     background-image: url(img/trybe.jpg);
//     height: 100vh;
//     min-height: 390px;
//     background-position: center;
//     background-size: cover;
//     background-repeat: no-repeat;
// }

.nextstepshome {
    background-image: url(/img/next-steps.jpg);
    height: 70vh;
    min-height: 390px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.ns-vid {
    background-color: white;
    width: 500px;
    height: 480px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
    margin: auto;
    position: relative;
    bottom: 250px;
}

.nextstepsvid {
    display: block;
}

.nextsteps {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.ns-body {
   display: flex;
}
.ns-img  { 
    display: flex;
    width: 40%;
    height: 280px;

    :nth-child(even) {
        position: absolute;
    }
    .ns-img img {
        border-radius: 10px;
    }
}
.ns-cnt {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    width: 400px;
    margin: auto;
}

// church life 

.col-md-2 {
    padding-bottom: 2rem;
}
.cl-img-main {
    background-image: url(/img/CL-1.JPG);
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 320px;

}
.cl-main-cnt {
    text-align: center;
    margin: 0 auto;
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 200px;
    font-family: 'Roboto Condensed', sans-serif;

}

.churchlife {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 4em;
    p {
        margin-top: 0;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        text-align: center;
        align-content: center;
        align-items: center;
        margin-left: 1em;
    }
    .churchlife img {
        border-radius: inherit;
        box-shadow: inherit;
    }
}
   
    .btn  {
        color: white;
        padding: 0.35rem 1.5rem;
        background-color: #CF4141ed;
        border: none;
        border-radius: 2px;
    }
    .cl-img {
        width: 350px;
        display: flex;
        justify-content: center;
        border-radius: 25px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        height: 300px;

        img {
            object-fit: cover;
            border-radius: inherit;
        }
    }
    .cl-cnt {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }



    // Values

    .values-box {
        width: 100%;
        height: auto;
        border-radius: 5px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        margin-bottom: 1.1rem;

        .values-cnt {
            display: none;
            height: auto;
            padding: 10px;
        }

        h3  {
            
            padding: 10px;
            margin: 0;
        }
    }

    h1.ourvalues  {
    font-family: "Satisfy", cursive;
}
//  Leadership

.leadershiphead {
    margin-top: 5rem;
}

#mainleader {
    display: flex;
    margin-bottom: 3rem;
    .leaderbody {
        display: flex;
        width: 80%;
        flex-direction: column;
        justify-content: center;

        h1 {
            font-size: 3.25rem;
            font-weight: bold;
        }
        p {
            font-size: 0.95rem;
        }
    }

    .leaderpic {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 30px;



        img {

            width: 450px;
            // border-radius: 50%;
            object-fit: cover;
    
        }

    }
}

// leadersocials
.socials a {
    margin-right: 30px;
}

// give

.give {
    background-image: url("/img/giv.jpg");
    overflow: hidden;
    background-size: cover;
    background-position: center;
    height: 70vh;
    max-height: 4200px;
}
.give-cnt {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    max-width: 500px;
    font-family: 'Chelsea Market', cursive;
    margin: auto;
    padding-top: 20px;
    color: white;

}

.giveinfo {
    margin: auto;
    width: 300px;
    text-align: center;
}

 #give-wide {
    background-color: #f4f5f7;
}

// give btn
#givenow{
    position:relative;
}



.give-wide-cnt2{
    padding-top: 1rem;
    background-color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.give-wide-cnt {
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;

    .give-img {
        width: auto;

        img {
            border-radius: 10px;
            -webkit-box-shadow: 0 10px 6px -6px #777;
            -moz-box-shadow: 0 10px 6px -6px #777;
                 box-shadow: 0 10px 6px -6px #777;
            max-height: 320px;
        }

    }
    .givenow {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.givenav .navbar-light .navbar-nav .nav-link {
    color: black;



}

.givenow {
    width: 50%;
}
.rowgfh .give-wide-cnt .paymentform {
    display: none;
}

.paymentform {
    display: flex;
    flex-direction: column;
    text-align: left;

}

.values-box2 {
    width: 100%;
    height: auto;
    cursor: pointer;
    margin-bottom: 1.1rem;
    text-align: center;

    .values-cnt {
        display: none;
        height: auto;
        padding: 10px;

    }
}

.givebuttons {
    display: flex;
}
// .logo {
//     background-image: url(img/lgs.png) !important;

// }



.cols {
    display: flex;
    width: 460px;
    height: 411px;
    margin: auto;

    .col-cnt {
        position: absolute;
        color: white;
    }

    img {
        object-fit: cover;
    }
}

// FUll image

.fullimage {
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));


    img.fullimage {
        height: 100vh;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        
    }
}

.fullcnt {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 600px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: white;
    text-align: center;
    // font-family: "Share Tech Mono";
    font-weight: 100;
    line-height: 1.7;
    letter-spacing: -0.025em;


    h1 {
        font-family: "Satisfy", cursive;
        font-size: 2.5rem;
    }
    h3 {
        font-size: 1rem;

    }

}


.fullsocials {
    display: flex;
    justify-content: space-evenly;
}

.crock {
    margin: 0 auto;
    padding-bottom: 3rem;
    padding-top: 3rem;
    text-align: center;
    h1 {
        margin-bottom: 2rem;
    }
}


.crocklinks  {
    
    
    h1 {
        font-family: 'Roboto Condensed', sans-serif;
    }

    a {
        text-decoration: none;
    }
}



.ncr-flex {
    display: flex;
    max-width: 800px;
    margin: 0 auto;

    .ncr-img {
        width: 50%;
    }

    .ncr-txt {
        width: 50%;
        // font-family: Share Tech Mono;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 30px;
        padding-left: 30px;
    }
}

.ncr-body {
    margin: 5rem auto;
    max-width: 790px;
    // font-family: Share Tech Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    padding: 0 20px 0 20px;
}


// easter

#full-easter {
    padding: 20px;
    //width: 1200px;
}

#select-e {
    margin-bottom: 10rem; 
    width:700px;
    margin: 0 auto;
}

.easter {
    width: auto;
    margin: 0 auto;

    h1 {
        font-family: "proxima_novablack", helvetica, serif;
        font-weight: 900;
        font-size: 2.5rem;
        line-height: 100%;
        font-size: 4rem;
    }
    h2 {
        font-weight: 700;
        font-size: 2rem;
    }
}

.location {
    background-color: #ffffff;
    border-radius: 0.5rem;
    //box-shadow: 0 0.125rem 0.375rem rgb(45 45 46 / 25%);
    color: #404041;
}

.blue-button {
    background-color: #009ecc;
color: #ffffff;
border-radius: 6.25rem;
cursor: pointer;
font-family: "proxima_novasemibold", helvetica, serif;
font-size: 1rem;
line-height: 100%;
padding: 0.75rem 2rem;
outline: none;
display: inline-block;
margin-bottom: 16px;
text-align: center;
vertical-align: middle;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
border: 1px solid transparent;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
text-decoration: none;
}

a {
    text-decoration: none;
}

.white-button {
    border-radius: 6.25rem;
    cursor: pointer;
    font-family: "proxima_novasemibold", helvetica, serif;
    font-size: 1rem;
    line-height: 100%;
    padding: 0.75rem 2rem;
    outline: none;
    display: inline-block;
    margin-bottom: 16px;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    background-color: transparent;
    border: 0.0625rem solid rgba(0, 158, 204, 0.2);
    color: #009ecc;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-options h3 {
    font-weight: 700;
    font-size: 20px;
    
    p {
        font-size: 1rem;
        font-family: "proxima_novaregular", helvetica, serif !important;
    }
}

// custom media queries mobile responsiveness!
@media screen and (max-width: 1029px){

    .nextsteps {
        display: block;
    }
    .ns-body {
        display: block;
        width: 100%;
    }
    .ns-img img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}


@media screen and (max-width: 992px) {
    .container-md, .container-sm, .container {
        max-width: 1200px;
    }
}

@media screen and (max-width: 823px)  {
    .vision {
        width: 100%;
    }
        .vision2 {
            width: auto;
            margin: 0 auto;
        }
        .mission {
            width: auto;
            margin: 0 auto;
        }
        .somequotebody {
            width: auto;
        }
    .homestuff .homeIMG {
        width: 100%;
    }
    .home-box {
        width: 100%;
    }
    // .row {
    //     display: block;
    // }
    .col-sm {
        margin: 0 auto 2.5%;


    }
    .col-cnt {
        position: absolute;
        top: 0;
    
    }
    .vimi {
        display: block;
    }
    .outreach-img {
        display: block;
        width: 100%;
    }
    .outreach {
        display: block;
        width: 100%;
    }
    .homestuff .homeIMG {
        width: 100%;
        margin: 0 auto;
    }
}


@media screen and (max-width: 800px) {
    .col-md-2 {
        display: none;

    }
    .cl-img{
        width: 100%;
        margin-bottom: 1.3rem;
        margin-top: 1.3rem;
    }

    .cl-cnt{
        width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .col-md-2 {
        display: none;
    }
    .row {
        width: 100%;
    }

    .crocklinks h1 {
        font-size: 4rem !important;
    }

    .fullcnt {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 2rem;

        h1 {
            font-size: 2rem;
        }
    }

    #mainleader .leaderpic {
        width: 100%;
        margin: auto;
    }

    .js-cookie-consent.cookie-consent {
        margin: auto;
        left: 50%;
    }

    .easter {
        h1 {
            font-size: 2rem;
        }
        h2 {
            font-size: 1.2rem;
        }
    }
    
    #select-e {
        width: auto;
    }

    .dropdown-options {
        width: auto;
    }



}

@media screen and (max-width: 767px) {

    .cl-main-cnt h1 {
        font-size: 1.2rem;
    }
    
        .nav-item {
            text-align: center;
            width: 200px;
        }
        
        .vid {
            width: 70%;
            margin-top: 1rem;
        }
    
    
        .navbar-nav  {
            z-index: 1;
            background-color: #fb5858eb;
            padding-bottom: 2rem;
            position: relative;
        }
    
        // .navbar-collapse {
        //     z-index: 1;
        //     background-color: #fb5858eb;
        //     padding-bottom: 2rem;
        // }
    
        #mainleader {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
    
            .socials {
                justify-content: center;
            }
        }
    }

@media screen and (max-width: 639px) {
    .row {
        width: 100%;


        .col{
            width: 100%;
            max-height: 100%;
        }

    }
.churchlife {
    display: block;

    .cl-img {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}


    .give-wide-cnt{
        flex-direction: column;
        align-items: center;
        width: 100%;

        .give-img {
            width: 100%;
        }
    }
        .givenow {
            padding-top: 2rem;
            width: 100% !important;
        }

        .give-wide-cnt .give-img img {
            margin: auto;
        }

        .fullcnt  {
            width: 100%;
            
        }
}



@media screen and (max-width: 550px){
    .row {
        display: block;
    }
    .a-row {
        width: 100%;
    }
    .give-wide-cnt2 {
        display: block;
    }
    .ncr-flex {
        display: block;

        .ncr-img {
            width: 100%;

        }
        .ncr-txt {
            width: 100%;
        }
    }

    .stream {
        display: flex;
        flex-direction: column;
    }

}






// CSS HOVER
/* Float Shadow */
.hvr-float-shadow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .hvr-float-shadow:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    // background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    // background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    /* W3C */
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
  }
  .hvr-float-shadow:hover, .hvr-float-shadow:focus, .hvr-float-shadow:active {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    /* move the element up by 5px */
  }
  .hvr-float-shadow:hover:before, .hvr-float-shadow:focus:before, .hvr-float-shadow:active:before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
  }


  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }


  .full-height{
    height:100vh;
}
.red{
    background-color: #F73656;
}
.blue{
    background-color: #47A3D1;
}
.green{
    background-color: #3ECC98;
}
.centered{
    margin: 0px auto;
}